/** @format */

import React, { useEffect, useState } from 'react';
import {
	Container,
	Placeholder,
	Segment,
	Image,
	Dimmer,
} from 'semantic-ui-react';
import './styles.css';
import _ from 'lodash';
import { getQuote } from '../../utils/quote';

// const url = 'https://goquotes-api.herokuapp.com/api/v1/random?count=1';

export const Random = () => {
	const [quote, setQuote] = useState();
	const [date, setDate] = useState(new Date().getDate());
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		if (!quote) {
			setQuote(() => getQuote());
		}

		if (quote) {
			setLoading(() => false);
		}
	}, [date, quote]);

	document.body.className = 'home';

	return (
		// <Container id='quote'>
		<div
			className='q-segment'
			style={{
				backgroundImage: 'url(' + quote?.image + ')',
			}}
		>
			{/* {isLoading && (
					<Placeholder fluid>
						<Placeholder.Image square />
					</Placeholder>
				)} */}
			{/* {!isLoading && (
				)} */}
			{/* <Dimmer.Dimmable> */}
			{/* <Image as='q' src={quote?.image} /> */}
			{/* </Dimmer.Dimmable> */}
			<div className='q-overlay'>
				<blockquote>
					{quote?.text ?? 'Uh oh'}
					{<cite>{quote?.author ?? 'Some dude'}</cite>}
				</blockquote>
			</div>
		</div>
		// </Container>
	);
};
