import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { BoldLink, BoxContainer, Marginer, SignInContext } from './index';
import { Error } from '../Errors';
import { Button, TextField, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Loader } from 'semantic-ui-react';
import _ from 'lodash';

export function SignUpForm(props) {
	const { switchToSignin } = useContext(SignInContext);
	const { authState, oktaAuth } = useOktaAuth();
	const history = useHistory();

	const [submitCreate, setSubmitCreate] = useState(false);
	const [userCreate, setCreate] = useState();
	const [isLoading, setLoading] = useState(false);
	const [oktaLoading, setOktaLoading] = useState(false);
	const [errorState, setError] = useState({ isError: false });

	const login = ({ username, password }) => {
		console.log('Logging user in...');
		return oktaAuth
			.signInWithCredentials({
				username: username,
				password: password,
				sendFingerprint: true,
			})
			.then((transaction) => {
				if (transaction.status === 'SUCCESS') {
					console.log('tx:', transaction.status);
					oktaAuth.signInWithRedirect({
						sessionToken: transaction.sessionToken,
					});
				}
			})
			.catch((err) => {
				setError(() => ({
					isError: true,
					error: err?.status,
					errorDetails: err?.error ?? err.toString(),
				}));
			});
	};

	const signup = (user) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(user),
		};

		fetch(`${window.location.origin}/api/users`, options)
			.then((response) => {
				if (!response.ok) {
					throw {
						status: response.status,
						error: response?.statusText,
					};
				} else if (response.ok) {
					return;
				}
			})
			.then(() => {
				setSubmitCreate(false);
				return login({
					username: user.profile.login,
					password: user.credentials.password.value,
				});
			})
			.catch((err) => {
				setLoading(false);
				setSubmitCreate(false);
				setError(() => ({
					isError: true,
					error: err?.status,
					errorDetails: err?.error ?? err.toString(),
				}));
			});
	};

	useEffect(() => {
		if (submitCreate) {
			const newUser = {
				profile: {
					login: userCreate.email,
					email: userCreate.email,
					firstName: userCreate.firstName,
					lastName: userCreate.lastName,
				},
				credentials: {
					password: {
						value: userCreate.password,
					},
				},
			};

			return signup(newUser);
		}
	}, [submitCreate]);

	const handleFormChange = (e) => {
		const id = e.target?.id ?? e.target?.name;
		const value = e.target.value;
		let change,
			create = userCreate;
		if (_.isEmpty(value)) {
			try {
				delete create[id];
			} catch {}
		} else {
			change = { [id]: value };
		}
		setCreate({ ...create, ...change });
	};

	const handleSubmitSignUp = (e) => {
		e.preventDefault();

		if (!userCreate) {
			setCreate(null);
		}

		setLoading(() => true);
		setCreate(() => ({ ...userCreate }));
		setSubmitCreate(() => true);
	};

	const useStyles = makeStyles((theme) => ({
		root: {
			'& .MuiTextField-root': {
				margin: theme.spacing(1),
				// transition: 'all 200ms ease-in-out',
			},
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		submitButton: {
			width: '100%',
			borderRadius: '20px',
			height: '40px',
		},
	}));

	const classes = useStyles();

	const zodiacs = [
		{ value: 'Aquarius', label: 'Aquarius' },
		{ value: 'Pisces', label: 'Pisces' },
		{ value: 'Aries', label: 'Aries' },
		{ value: 'Taurus', label: 'Taurus' },
		{ value: 'Gemini', label: 'Gemini' },
		{ value: 'Cancer', label: 'Cancer' },
		{ value: 'Leo', label: 'Leo' },
		{ value: 'Virgo', label: 'Virgo' },
		{ value: 'Scorpio', label: 'Scorpio' },
		{ value: 'Saggitarius', label: 'Saggitarius' },
		{ value: 'Capricorn', label: 'Capricorn' },
	];

	return (
		<BoxContainer>
			{errorState.isError && (
				<Error
					error={errorState.error}
					errorDetails={errorState.errorDetails}
				/>
			)}
			<form className={classes.root}>
				<TextField
					required
					variation='filled'
					id='email'
					type='email'
					label='Email'
					onChange={handleFormChange}
					disabled={isLoading}
				/>
				<TextField
					required
					variation='filled'
					id='password'
					type='password'
					autoComplete='new-password'
					label='Password'
					onChange={handleFormChange}
					disabled={isLoading}
				/>
				<TextField
					required
					variation='filled'
					id='firstName'
					type='text'
					autoComplete='given-name'
					label='First Name'
					onChange={handleFormChange}
					disabled={isLoading}
				/>
				<TextField
					required
					variation='filled'
					id='lastName'
					type='text'
					autoComplete='family-name'
					label='Last Name'
					onChange={handleFormChange}
					disabled={isLoading}
				/>
				<TextField
					id='tropicalZodiac'
					name='tropicalZodiac'
					label='Zodiac Sign'
					variation='filled'
					onChange={handleFormChange}
					disabled={isLoading}
					value={userCreate?.tropicalZodiac ?? ''}
					select
				>
					{zodiacs.map((option) => (
						<MenuItem
							id='tropicalZodiac'
							key={option.value}
							value={option.value}
							name='tropicalZodiac'
						>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</form>
			<Marginer direction='vertical' margin={10} />
			<Marginer direction='vertical' margin='1.6em' />
			<Button
				type='submit'
				variant='contained'
				color='primary'
				className={classes.submitButton}
				onClick={handleSubmitSignUp}
			>
				<label>
					{isLoading && (
						<Loader active size='small' inverted inline='centered' />
					)}
					{!isLoading && 'Sign Up'}
				</label>
			</Button>
			<Divider section horizontal content='or' />
			<Button
				type='submit'
				variant='contained'
				color='secondary'
				className={classes.submitButton}
				onClick={() => oktaAuth.signInWithRedirect()}
			>
				<label>
					{oktaLoading && (
						<Loader active size='small' inverted inline='centered' />
					)}
					{!oktaLoading && 'Continue with Okta'}
				</label>
			</Button>
			<Divider section />
			<Typography variant='caption'>
				Already have an account?
				<BoldLink href='#' onClick={switchToSignin}>
					Signin
				</BoldLink>
			</Typography>
			<Marginer direction='vertical' margin='2em' />
		</BoxContainer>
	);
}
