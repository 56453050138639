/** @format */

import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
import { Grid, Loader, Table } from 'semantic-ui-react';
import { AnimatedIcon } from '../AnimatedIcons';

export const Introspect = () => {
	const { authState, oktaAuth } = useOktaAuth();
	const [userInfo, setUserInfo] = useState();
	const [introspection, setIntrospect] = useState();
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState();

	// handles user auth state
	useEffect(() => {
		console.log('isAuthenticated:', authState.isAuthenticated);
		if (!authState.isAuthenticated) {
			setLoading(() => {
				return false;
			});
		} else {
			setLoading(() => true);
			oktaAuth
				.getUser()
				.then(usrInfo => {
					setUserInfo(() => {
						return { ...usrInfo };
					});
					return usrInfo;
				})
				.then(() => {
					const idToken = oktaAuth.getIdToken();
					const options = {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${idToken}`,
						},
					};

					return fetch(
						window.location.origin +
							'/api/introspect?client_id=' +
							process.env.REACT_APP_OKTA_CLIENT_ID,
						options
					)
						.then(response => response.json())
						.then(data => {
							console.log('data:', data);
							setIntrospect({ ...data });
							return data;
						});
				})
				.then(() => {
					setLoading(false);
				})
				.catch(err => {
					setError(err);
					throw new Error(`Unable to do /introspect [${err}]`);
				});
		}
	}, [authState, oktaAuth]);

	return (
		<Grid stackable container divided='vertically' columns={2}>
			<Grid.Row>
				<Grid.Column>
					<Table stackable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Claim</Table.HeaderCell>
								<Table.HeaderCell>Value</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<tbody>
							{!isLoading &&
								Object.entries(introspection).map(claimEntry => {
									const claimName = claimEntry[0];
									const claimValue = claimEntry[1].toString();
									const claimId = `claim-${claimName}`;

									if (
										[
											'treasure.hook',
											'treasure',
											'active',
											'at_hash',
											'auth_time',
										].indexOf(claimName) >= 0
									) {
										return (
											<tr key={claimName}>
												<td>{claimName}</td>
												<td id={claimId}>{claimValue}</td>
											</tr>
										);
									}
								})}
						</tbody>
					</Table>
				</Grid.Column>
				<Grid.Column>
					{isLoading && <Loader active>Doing Introspection...</Loader>}
					{!isLoading && introspection?.active && (
						<AnimatedIcon label='Token Valid!' success />
					)}
					{!isLoading && !introspection?.active && (
						<AnimatedIcon label='Not Valid!' />
					)}
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};
