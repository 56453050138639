/** @format */

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const BoxContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
// margin: 0px 0px 10px 0px;

export const FormContainer = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const Subtitle = styled.p`
	font-size: 11px;
	color: rgba(32, 44, 57, 0.8);
	font-weight: 500;
	text-decoration: none;
`;

export const MutedLink = styled.a`
	font-size: 11px;
	color: rgba(32, 44, 57, 0.8);
	font-weight: 500;
	text-decoration: none;
	text-align: center;
`;

export const BoldLink = styled.a`
	font-size: 11px;
	font-weight: 500;
	text-decoration: none;
	margin: 0 4px;
`;
// color: rgb(245, 114, 0, 1);

export const Input = styled.input`
	width: 100%;
	height: 42px;
	outline: none;
	border: 1px solid rgba(200, 200, 200, 0.3);
	padding: 0px 10px;
	border-bottom: 1.4px solid transparent;
	transition: all 200ms ease-in-out;
	font-size: 12px;

	&::placeholder {
		color: rgba(200, 200, 200, 1);
	}

	&:not(:last-of-type) {
		border-bottom: 1.5px solid rgba(200, 200, 200, 0.4);
	}

	&:focus {
		outline: none;
		border-bottom: 2px solid rgb(241, 196, 15);
	}
`;

export const SubmitButton = styled.button`
	width: 100%;
	padding: 11px 40%;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	border: none;
	border-radius: 100px 100px 100px 100px;
	cursor: pointer;
	transition: all, 240ms ease-in-out;
	background: rgb(63, 81, 181);
	background: linear-gradient(
		58deg,
		rgba(96, 111, 199, 1) 20%,
		rgba(63, 81, 181) 100%
	);

	&:hover {
		filter: brightness(1.2);
	}
`;

export const TopContainer = styled.div`
	width: 100%;
	height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 1.8em;
`;

export const BackDrop = styled(motion.div)`
	width: 160%;
	height: 550px;
	position: absolute;
	display: flex;
	flex-direction: column;
	border-radius: 50%;
	z-index: 10;
	top: -290px;
	left: -70px;
	transform: rotate(60deg);

	background: rgb(63, 81, 181);
	background: linear-gradient(
		58deg,
		rgba(96, 111, 199, 1) 20%,
		rgba(63, 81, 181) 100%
	);
`;

export const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const HeaderText = styled.h2`
	font-size: 30px;
	font-weight: 600;
	line-height: 1.24;
	color: #fff;
	z-index: 10;
	margin: 0;
`;

export const SmallText = styled.h5`
	color: #fff;
	font-weight: 500;
	font-size: 11px;
	z-index: 10;
	margin: 0;
	margin-top: 7px;
`;

export const InnerContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 1.8em;
`;

export const SignInContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
