/** @format */

import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Container, Loader } from 'semantic-ui-react';
import { DataTable } from '../DataTable';

import { AnimatedIcon } from '../AnimatedIcons';
import { Error } from '../Errors';
import { includes } from 'lodash-es';

export const UsersDataTable = () => {
	document.getElementsByTagName('body')[0].removeAttribute('class');
	const { authState, oktaAuth } = useOktaAuth();
	const [users, setUsers] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [errorState, setError] = useState({ isError: false });
	const [isAuthorized, setAuthorized] = useState(false);
	const [isAdmin, setAdmin] = useState(false);

	const columns = [
		{
			name: 'Id',
			selector: row => row.id,
			type: 'string',
		},
		{
			name: 'Name',
			selector: row =>
				row.profile?.displayName
					? row.profile.displayName
					: `${row.profile.firstName} ${row.profile.lastName}`,
			type: 'string',
		},
		{
			name: 'Username',
			selector: row => row.profile.login,
			type: 'string',
		},
		{
			name: 'Status',
			selector: row => row.status,
			type: 'string',
		},
	];

	useEffect(() => {
		if (!authState.isAuthenticated) {
			setAuthorized(() => false);
			setUsers([]);
			setLoading(false);
		} else {
			setLoading(true);
			// Fetch access_token
			const accessToken = oktaAuth.getAccessToken();
			if (!accessToken) {
				resetState();
			} else {
				const jwt = oktaAuth.token.decode(accessToken);
				const payload = jwt.payload;
				const permissions = payload?.permissions;

				console.log('admin:all', permissions?.includes('admin:all'));

				setAdmin(() => permissions?.includes('admin:all') ?? false);
				setAuthorized(() =>
					permissions?.includes('users:read') ||
					permissions?.includes('admin:all')
						? true
						: false
				);
			}

			fetch('api/users', {
				headers: { authorization: `Bearer ${accessToken}` },
			})
				.then(response => {
					if (!response.ok) {
						if (response.status === 401) {
							return setAuthorized(() => false);
						}

						throw {
							status: response.status,
							error: response?.statusText,
						};
					} else {
						try {
							return response.json();
						} catch {}
						try {
							return response.text();
						} catch {}
					}
				})
				.then(data => {
					setUsers(data);
					setLoading(() => false);
				})
				.catch(err => {
					console.log('fetch:', err);
					setError(() => ({
						isError: true,
						error: err?.status,
						errorDetails: err.error ?? err.toString(),
					}));
				});
		}
	}, [authState, oktaAuth]);

	const resetState = () => {
		setUsers(() => {});
		setAuthorized(() => false);
		setLoading(() => false);
	};

	return (
		<Container text style={{ marginTop: '5em' }}>
			{isLoading && !isAuthorized && <Loader active />}
			{!isLoading && !isAuthorized && <AnimatedIcon label='Not Authorized' />}
			{!isLoading && isAuthorized && errorState.isError && (
				<Error
					error={errorState.error}
					errorDetails={errorState.errorDetails}
				/>
			)}
			{isAuthorized && (
				<DataTable
					columns={columns}
					data={users}
					rows={users.length}
					disablePagination={true}
					enableSearch={true}
					loading={isLoading}
				/>
			)}
		</Container>
	);
};
