/** @format */

import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Input, Divider, Grid, Form, Popup, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import { Error } from '../Errors';
import { AnimatedIcon } from '../AnimatedIcons';

export const About = ({
	loading,
	self,
	userProfile,
	edit,
	sensitive,
	admin,
	onUpdate,
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [profile, setUserProfile] = useState();
	const [userUpdate, setUpdate] = useState();
	const [isFormLoading, setFormLoading] = useState(false);
	const [submitUpdate, setSubmitUpdate] = useState(false);
	const [errorState, setError] = useState({ isError: false });
	const [isAdmin, setIsAdmin] = useState();
	const [canEdit, setCanEdit] = useState();
	const [isSensitive, setSensitive] = useState();
	const [isMastered, setMastered] = useState();

	// load profile
	useEffect(() => {
		if (!userProfile) {
			setUserProfile(() => null);
			setFormLoading(() => true);
		} else {
			setUserProfile(() => userProfile);
			setFormLoading(() => false);
			setMastered(() => userProfile?.provider === 'SOCIAL');
		}
	}, [userProfile]);

	useEffect(() => {
		setIsAdmin(() => admin);
		setCanEdit(() => edit);
		setSensitive(() => sensitive);
	});

	// do userUpdate
	useEffect(() => {
		if (submitUpdate) {
			console.log('calling update...');

			onUpdate(userUpdate, profile.id);
		}
	}, [submitUpdate]);

	const handleCancel = () => {
		setIsEdit(false);
		setUpdate(() => {
			return;
		});
	};

	const handleEdit = () => {
		if (!isEdit) {
			return setIsEdit(true);
		}
	};

	const handleFormChange = (e, { id, value }) => {
		let change = { [id]: value };
		// console.log(change);
		setUpdate(change);
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!userUpdate) {
			return handleCancel();
		}
		setIsEdit(() => {
			return false;
		});
		setFormLoading(() => {
			return true;
		});
		setUpdate(() => {
			return { ...userUpdate };
		});
		setSubmitUpdate(() => {
			return true;
		});
	};

	// calc sensitive fields
	let ssn, mmn, bday;
	if (isSensitive || self) {
		ssn = profile?.ssn ?? '';
		mmn = profile?.mothersMaidenName ?? '';
		bday = profile?.birthday_facebook ?? profile?.birthday ?? '';
	} else if (!isSensitive) {
		const rawSSN = profile?.ssn;
		const dob = profile?.birthday_facebook ?? profile?.birthday;
		ssn = rawSSN ? rawSSN.substr(0, 4) + '**-****' : '***-**-****';
		mmn = '***************';
		bday = dob ? dob.substr(0, 6) + '****' : '**/**/****';
	}

	const zodiacOptions = [
		{ key: 'aquarius', text: 'Aquarius', value: 'Aquarius' },
	];

	return (
		<div>
			{/* {!isAuthorized && <AnimatedIcon label='Not authorized!' />} */}
			{!loading && (
				<Form
					onSubmit={handleSubmit}
					loading={!loading && isFormLoading}
					error={errorState.isError}
				>
					{errorState?.isError && (
						<Error
							error={errorState?.error}
							errorDetails={errorState?.errorDetails}
						/>
					)}
					<Form.Group widths='equal'>
						<Form.Field id='firstName'>
							<label style={{ display: 'inline' }}>First Name </label>
							{isMastered && (
								<Popup
									content='Managed by Facebook'
									size='mini'
									trigger={<Icon name='info circle' />}
									style={{ display: 'inline' }}
								/>
							)}
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.firstName || ''}
									</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='given-name'
									id='firstName'
									placeholder='First Name'
									defaultValue={profile?.firstName || ''}
									disabled={isMastered}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='middleName'>
							<label style={{ display: 'inline' }}>Middle Name </label>
							{isMastered && (
								<Popup
									content='Managed by Facebook'
									size='mini'
									trigger={<Icon name='info circle' />}
									style={{ display: 'inline' }}
								/>
							)}
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.middleName || ''}
									</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='additional-name'
									id='middleName'
									placeholder='Middle Name'
									defaultValue={profile?.middleName || ''}
									disabled={isMastered}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='lastName'>
							<label style={{ display: 'inline' }}>Last Name </label>
							{isMastered && (
								<Popup
									content='Managed by Facebook'
									size='mini'
									trigger={<Icon name='info circle' />}
									style={{ display: 'inline' }}
								/>
							)}
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.lastName || ''}
									</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='family-name'
									id='lastName'
									placeholder='Last Name'
									defaultValue={profile?.lastName || ''}
									disabled={isMastered}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group widths='equal'>
						<Form.Field id='login'>
							<label style={{ display: 'inline' }}>Username </label>
							{isMastered && (
								<Popup
									content='Managed by Facebook'
									size='mini'
									trigger={<Icon name='info circle' />}
									style={{ display: 'inline' }}
								/>
							)}
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>{profile?.login || ''}</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='username'
									id='login'
									placeholder='Username'
									defaultValue={profile?.login || ''}
									disabled={isMastered}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='email'>
							<label style={{ display: 'inline' }}>Email </label>
							{isMastered && (
								<Popup
									content='Managed by Facebook'
									size='mini'
									trigger={<Icon name='info circle' />}
									style={{ display: 'inline' }}
								/>
							)}
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>{profile?.email || ''}</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='email'
									id='email'
									placeholder='Email'
									defaultValue={profile?.email || ''}
									disabled={isMastered}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group widths='equal'>
						<Form.Field id='primaryPhone'>
							<label>Primary Phone</label>
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.primaryPhone || ''}
									</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='tel'
									id='primaryPhone'
									placeholder='Primary Phone'
									defaultValue={profile?.primaryPhone || ''}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='mobilePhone'>
							<label>Mobile Phone</label>
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.mobilePhone || ''}
									</span>
								</div>
							)}
							{isEdit && (
								<Input
									type='text'
									autoComplete='tel'
									id='mobilePhone'
									placeholder='Mobile Phone'
									defaultValue={profile?.mobilePhone || ''}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='tropicalZodiac'>
							<label>Zodiac Sign</label>
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.tropicalZodiac || ''}
									</span>
								</div>
							)}
							{isEdit && (
								<div>
									<Input
										list='zodiacs'
										id='tropicalZodiac'
										placeholder='Choose Zodiac Sign...'
										defaultValue={profile?.tropicalZodiac || ''}
										onChange={handleFormChange}
									/>
									<datalist id='zodiacs'>
										<option value='Aquarius'>Aquarius</option>
										<option value='Pisces'>Pisces</option>,
										<option value='Aries'>Aries</option>
										<option value='Taurus'>Taurus</option>
										<option value='Gemini'>Gemini</option>
										<option value='Cancer'>Cancer</option>
										<option value='Leo'>Leo</option>
										<option value='Virgo'>Virgo</option>
										<option value='Scorpio'>Scorpio</option>
										<option value='Saggitarius'>Saggitarius</option>
										<option value='Capricon'>Capricon</option>
									</datalist>
								</div>
							)}
						</Form.Field>
					</Form.Group>
					{self && (
						<Form.Group widths='equal'>
							<Form.Field id='lastLogin' width={5}>
								<label>Last Login</label>
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{profile?.last_login
											? new Date().toLocaleString(
													new Date().getTime(profile.last_login)
											  )
											: ''}
									</span>
								</div>
							</Form.Field>
						</Form.Group>
					)}
					<Divider horizontal section>
						SENSITIVE ATTRIBUTES
					</Divider>
					<Form.Group widths='equal'>
						<Form.Field id='ssn'>
							<label>Social Security Number</label>
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{self || isAdmin || isSensitive ? profile?.ssn : ssn}
									</span>
								</div>
							)}
							{(isAdmin || self) && isEdit && (
								<Input
									type='text'
									id='ssn'
									placeholder='Social Security Number'
									defaultValue={profile?.ssn}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='mothersMaidenName'>
							<label>Mother's Maiden Name</label>
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{self || isAdmin || isSensitive
											? profile?.mothersMaidenName
											: mmn}
									</span>
								</div>
							)}
							{(isAdmin || self) && isEdit && (
								<Input
									type='text'
									id='mothersMaidenName'
									placeholder="Mother's Maiden Name"
									defaultValue={profile?.mothersMaidenName}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
						<Form.Field id='birthday'>
							<label style={{ display: 'inline' }}>Birthday </label>
							{isMastered && (
								<Popup
									content='Managed by Facebook'
									size='mini'
									trigger={<Icon name='info circle' />}
									style={{ display: 'inline' }}
								/>
							)}
							{!isEdit && (
								<div className='ui input'>
									<span style={{ padding: 9.5 }}>
										{self || isAdmin || isSensitive
											? profile?.birthday_facebook ?? profile?.birthday
											: bday}
									</span>
								</div>
							)}
							{(isAdmin || self) && isEdit && (
								<Input
									type='date'
									id='birthday'
									placeholder='Birthday'
									defaultValue={profile?.birthday || ''}
									disabled={isMastered}
									onChange={handleFormChange}
								/>
							)}
						</Form.Field>
					</Form.Group>
					<Divider hidden />
					{canEdit && (
						<Grid container>
							<Grid.Row centered>
								{!isEdit && (
									<Form.Button
										primary
										content='Modify'
										onClick={handleEdit}
										loading={isFormLoading}
									/>
								)}
								{isEdit && (
									<Form.Button
										primary
										type='submit'
										content='Update'
										loading={isFormLoading}
									/>
								)}
								{isEdit && (
									<Form.Button
										type='button'
										content='Cancel'
										onClick={handleCancel}
									/>
								)}
							</Grid.Row>
						</Grid>
					)}
				</Form>
			)}
		</div>
	);
};
