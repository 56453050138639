import { Icon } from 'semantic-ui-react';
import './index.css';

const AnimatedCheck = ({ label, width }) => {
	return (
		<div>
			<Icon
				as='svg'
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 130.2 130.2'
				width={width ?? '100px'}
			>
				<circle
					className='path circle'
					fill='none'
					stroke='#73AF55'
					strokeWidth='6'
					strokeMiterlimit='10'
					cx='65.1'
					cy='65.1'
					r='62.1'
				/>
				<polyline
					className='path check'
					fill='none'
					stroke='#73AF55'
					strokeWidth='6'
					strokeLinecap='round'
					strokeMiterlimit='10'
					points='100.2,40.2 51.5,88.8 29.8,67.5 '
				/>
			</Icon>
			<p className='success'>{label}</p>
		</div>
	);
};

const AnimatedX = ({ label, width }) => (
	<div>
		<Icon
			as='svg'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 130.2 130.2'
			width={width ?? '100px'}
		>
			<circle
				className='path circle'
				fill='none'
				stroke='#D06079'
				strokeWidth='6'
				strokeMiterlimit='10'
				cx='65.1'
				cy='65.1'
				r='62.1'
			/>
			<line
				className='path line'
				fill='none'
				stroke='#D06079'
				strokeWidth='6'
				strokeLinecap='round'
				strokeMiterlimit='10'
				x1='34.4'
				y1='37.9'
				x2='95.8'
				y2='92.3'
			/>
			<line
				className='path line'
				fill='none'
				stroke='#D06079'
				strokeWidth='6'
				strokeLinecap='round'
				strokeMiterlimit='10'
				x1='95.8'
				y1='38'
				x2='34.4'
				y2='92.2'
			/>
		</Icon>
		<p className='error'>{label}</p>
	</div>
);

export const AnimatedIcon = ({ label, width, success }) => {
	if (success) {
		return AnimatedCheck({ label, width });
	} else {
		return AnimatedX({ label, width });
	}
};
