/** @format */

import { useState, useEffect } from 'react';
import ReactEmbedGist from 'react-embed-gist';
import {
	Accordion,
	Container,
	Divider,
	Header,
	Icon,
	Label,
	Segment,
} from 'semantic-ui-react';
import './styles.css';

export const Samples = () => {
	document.body.className = 'sign-in';

	const [panels, setPanels] = useState();
	const [data, setData] = useState();
	const [activeIndex, setIndex] = useState();

	useEffect(() => {
		const buildPanels = data => {
			let panels = [];
			data.forEach((item, index) => {
				let title = {
					as: Segment,
					content: (
						<div>
							{item.title}
							{item.isOIE && (
								<Label
									size='mini'
									attached='top right'
									color='blue'
									content='Identity Engine'
								/>
							)}
						</div>
					),
				};
				panels.push({
					key: `panel-${index}`,
					title: title,
					content: {
						content: <ReactEmbedGist gist={item.gist} titleClass='ui header' />,
					},
				});
			});
			return panels;
		};

		return fetch('/static/samples.json')
			.then(resp => {
				return resp.json();
			})
			.then(resp => {
				setData(() => resp?.data);
				return buildPanels(resp?.data);
			})
			.then(resp => setPanels(() => resp));
	}, []);

	return (
		<Container text style={{ marginTop: '5em' }} className={'ref-container'}>
			<Header as='h1'>Sign In Widget</Header>{' '}
			<Header as='h3'>Samples &amp; Hacks</Header>
			The following samples can be inserted into the Sign In Widget web-editor
			to provide enhanced capabilities for the Okta-hosted Widget.
			<br />
			<br />
			Note that this code is <b>not</b> production tested. Use at your own risk.
			<Divider section />
			<Container style={{ textAlign: 'left' }}>
				<Accordion styled exclusive={false} panels={panels} fluid />
			</Container>
		</Container>
	);
};
