/** @format */

import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	List,
	Card,
	Checkbox,
	Button,
	Divider,
	CardHeader,
	Icon,
} from 'semantic-ui-react';

function not(a, b) {
	console.log('a:', a);
	console.log('b:', b);
	return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
	return [...a, ...not(b, a)];
}

const admin = 'admin:all';

const availablePermissions = [
	admin,
	'users:read',
	'users:update',
	'users.sensitive:read',
];

export const PermissionsTransferList = ({ userProfile, onUpdate }) => {
	const [profile, setUserProfile] = useState();
	const [userPermissions, setPermissions] = useState([]);
	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState([]);
	const [right, setRight] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [submitUpdate, setSubmitUpdate] = useState(false);
	const [userUpdate, setUpdate] = useState();
	const [errorState, setError] = useState({ isError: false });

	// load profile
	useEffect(() => {
		if (!userProfile) {
			setUserProfile(() => null);
			setLoading(() => false);
		} else {
			setUserProfile(() => userProfile);
			setLoading(() => false);
		}
	}, [userProfile]);

	// set permissions
	useEffect(() => {
		const permissions = profile?.permissions ?? [];

		if (permissions.includes(admin)) {
			setPermissions(() => availablePermissions);
		} else {
			setPermissions(() => permissions);
		}
	}, [profile]);

	// set lists
	useEffect(() => {
		setIsDisabled(() => userPermissions.includes(admin));
		setLeft(() => userPermissions);
		setRight(() => not(availablePermissions, userPermissions));
	}, [userPermissions]);

	// do userUpdate
	useEffect(() => {
		if (submitUpdate) {
			console.log('initiating update...');
			const permissions = onUpdate(userUpdate, profile.id).then(resp =>
				setPermissions(() => permissions)
			);
		}
		return setSubmitUpdate(() => false);
	}, [submitUpdate, profile]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = items => intersection(checked, items ?? []).length;

	const handleToggleAll = items => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items ?? []));
		} else {
			setChecked(union(checked, items ?? []));
		}
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleSubmit = e => {
		e.preventDefault();

		setLoading(() => true);
		setUpdate(() => ({ ...userUpdate }));
		setSubmitUpdate(() => true);
		let change = { permissions: left };
		console.log('change:', change);
		setUpdate(change);
	};

	const CustomList = (title, items) => (
		<Card raised>
			<Card.Content>
				<List>
					<List.Item>
						<List.Icon>
							<Checkbox
								onClick={handleToggleAll(items)}
								checked={
									numberOfChecked(items) === items.length && items.length !== 0
								}
								indeterminate={
									numberOfChecked(items) !== items.length &&
									numberOfChecked(items) !== 0
								}
								disabled={items.length === 0}
								props={{ 'aria-label': 'all items selected' }}
							/>
						</List.Icon>
						<List.Content>
							<List.Header>{title}</List.Header>
							<List.Description>
								{`${numberOfChecked(items)}/${items.length} selected`}
							</List.Description>
						</List.Content>
					</List.Item>
				</List>
			</Card.Content>
			<Card.Content>
				<List role='list'>
					{items.map(value => {
						const labelId = `transfer-list-all-item-${value}-label`;
						return (
							<List.Item
								key={value}
								role='listitem'
								onClick={
									value === admin
										? handleToggleAll(items)
										: !isDisabled
										? handleToggle(value)
										: null
								}
							>
								<List.Icon>
									<Checkbox
										checked={checked.indexOf(value) !== -1}
										tabIndex={-1}
										disabled={value !== admin && isDisabled}
									/>
								</List.Icon>
								<List.Content id={labelId}>{value}</List.Content>
							</List.Item>
						);
					})}
				</List>
			</Card.Content>
		</Card>
	);
	return (
		<Grid centered padded='horizontally' columns={3}>
			<Grid.Row>
				<Grid.Column width={5}>{CustomList('Assigned', left)}</Grid.Column>
				<Grid.Column width={2} textAlign='center' verticalAlign='middle'>
					<Grid.Row>
						<Button
							variant='outlined'
							size='small'
							onClick={handleCheckedRight}
							disabled={leftChecked.length === 0}
							aria-label='move selected right'
						>
							&gt;
						</Button>
					</Grid.Row>
					<Grid.Row>
						<Button
							variant='outlined'
							size='small'
							onClick={handleCheckedLeft}
							disabled={rightChecked.length === 0}
							aria-label='move selected right'
						>
							&lt;
						</Button>
					</Grid.Row>
				</Grid.Column>
				<Grid.Column width={5}>{CustomList('Available', right)}</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Button primary onClick={handleSubmit} loading={isLoading}>
					Save
				</Button>
			</Grid.Row>
		</Grid>
	);
};
