/** @format */

import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Divider, Loader } from 'semantic-ui-react';
import _ from 'lodash';

import {
	BoldLink,
	BoxContainer,
	Marginer,
	MutedLink,
	Subtitle,
	SignInContext,
	SubmitButton,
} from './index';
import {
	Button,
	Input,
	Link,
	TextField,
	Typography,
	FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export function SignInForm(props) {
	const { switchToSignup } = useContext(SignInContext);
	const { authState, oktaAuth } = useOktaAuth();
	const history = useHistory();

	const [submitLogin, setSubmitLogin] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [oktaLoading, setOktaLoading] = useState(false);
	const [userLogin, setLogin] = useState();
	const [errorState, setError] = useState({ isError: false });

	const login = async ({ username, password }) => {
		oktaAuth
			.signInWithCredentials({
				username: username,
				password: password,
				sendFingerprint: true,
			})
			.then(async transaction => {
				if (transaction.status === 'SUCCESS') {
					oktaAuth.signInWithRedirect({
						sessionToken: transaction.sessionToken,
					});
				}
			})
			.catch(err => {
				// console.error('Login error:', err);
				if (err instanceof Error || err instanceof TypeError) {
					const error = {
						isError: true,
						error: err?.message,
						errorDetails: err?.stack,
					};
					console.error(error);
					setError(() => error);
				} else {
					setError(() => ({
						isError: true,
						error: err?.status,
						errorDetails: err?.error,
					}));
				}
			});
	};

	const silentLogin = async () => {
		oktaAuth.token
			.getWithoutPrompt()
			.then(resp => {
				let tokens = resp.tokens;

				oktaAuth.tokenManager.setTokens(tokens);
			})
			.catch(err => {
				if (err instanceof Error || err instanceof TypeError) {
					const error = {
						isError: true,
						error: err?.message,
						errorDetails: err?.stack,
					};
					setError(() => error);
				} else {
					setError(() => ({
						isError: true,
						error: err?.status,
						errorDetails: err?.error,
					}));
				}
			});
	};

	useEffect(() => {
		oktaAuth.session
			.exists()
			.then(async resp => {
				if (resp) {
					await silentLogin();

					if (await oktaAuth.isAuthenticated()) {
						history.push('/');
					}
				}
			})
			.catch(err => {
				if (err instanceof Error || err instanceof TypeError) {
					const error = {
						isError: true,
						error: err?.message,
						errorDetails: err?.stack,
					};
					setError(() => error);
				} else {
					setError(() => ({
						isError: true,
						error: err?.status,
						errorDetails: err?.error,
					}));
				}
			});
		setLoading(() => false);
	}, []);
	useEffect(() => {
		if (submitLogin) {
			setLoading(true);
			return login({
				username: userLogin.username,
				password: userLogin.password,
			});
		}
	}, [submitLogin]);

	const useStyles = makeStyles(theme => ({
		root: {
			'& .MuiTextField-root': {
				margin: theme.spacing(1),
				transition: 'all 200ms ease-in-out',
			},
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		submitButton: {
			width: '100%',
			borderRadius: '20px',
			height: '40px',
		},
	}));

	const classes = useStyles();

	const handleFormChange = e => {
		const id = e.target.id;
		const value = e.target.value;
		let submit,
			login = userLogin;
		if (_.isEmpty(value)) {
			try {
				delete login[id];
			} catch {}
		} else {
			submit = { [id]: value };
		}
		setLogin({ ...login, ...submit });
	};

	const handleSubmitSignIn = e => {
		e.preventDefault();

		if (!userLogin) {
			setLogin(null);
		}

		setLoading(() => true);
		setLogin(() => ({ ...userLogin }));
		setSubmitLogin(() => true);
	};

	return (
		<BoxContainer>
			{/* {errorState.isError && (
				<Error
					error={errorState.error}
					errorDetails={errorState.errorDetails}
				/>
			)} */}
			<form className={classes.root} onSubmit={handleSubmitSignIn}>
				<TextField
					autoFocus
					color='primary'
					required
					variant='filled'
					id='username'
					label='Email'
					type='username'
					onChange={handleFormChange}
					disabled={isLoading}
				/>
				<TextField
					required
					variant='filled'
					id='password'
					label='Password'
					type='password'
					onChange={handleFormChange}
					disabled={isLoading}
				/>
				<Marginer direction='vertical' margin={10} />
				<MutedLink
					variant='body2'
					href='https://identity.fuhriman.app/signin/forgot-password'
				>
					Forget your password?
				</MutedLink>
				<Marginer direction='vertical' margin='1.6em' />
				<Button
					type='submit'
					variant='contained'
					color='primary'
					className={classes.submitButton}
					// onClick={handleSubmitSignIn}
					// onSubmit={handleSubmitSignIn}
				>
					<label>
						{isLoading && (
							<Loader active size='small' inverted inline='centered' />
						)}
						{!isLoading && 'Sign In'}
					</label>
				</Button>
				<Divider section horizontal content='or' />
				<Button
					variant='contained'
					color='secondary'
					className={classes.submitButton}
					onClick={() => {
						setOktaLoading(() => true);
						return oktaAuth.signInWithRedirect();
					}}
				>
					<label>
						{oktaLoading && (
							<Loader active size='small' inverted inline='centered' />
						)}
						{!oktaLoading && 'Continue with Okta'}
					</label>
				</Button>
			</form>
			<Divider section />
			{/* <Marginer direction='vertical' margin='1em' /> */}
			<Typography variant='caption'>
				Don't have an account?{' '}
				<BoldLink href='#' onClick={switchToSignup}>
					Signup
				</BoldLink>
			</Typography>
			<Marginer direction='vertical' margin='2em' />
		</BoxContainer>
	);
}
