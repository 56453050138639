/** @format */

import React, { useState, useEffect } from 'react';
import { Table, Label, Tab, Modal, Button } from 'semantic-ui-react';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

export const UserClaims = ({
	isLoading = false,
	isAuthorized = true,
	data,
	isUserInfo,
}) => {
	const [intro, setIntro] = useState();
	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		if (isUserInfo) {
			setIntro(() => {
				return (
					<p>
						Below is the information obtained by making a `/userinfo` call using
						the ID Token obtained during the &nbsp;
						<a href='https://developer.okta.com/docs/guides/implement-auth-code-pkce'>
							PKCE Flow
						</a>{' '}
						.
					</p>
				);
			});
		} else {
			setIntro(() => {
				return (
					<p>
						Below are the claims from your tokens that were obtained during the
						&nbsp;
						<a href='https://developer.okta.com/docs/guides/implement-auth-code-pkce'>
							PKCE Flow
						</a>{' '}
						and decoded using the Okta Auth-JS SDK.
					</p>
				);
			});
		}
	}, [isUserInfo]);

	const TableBody = bodyData => (
		<Table.Body>
			{Object.entries(bodyData).map(claimEntry => {
				const claimName = claimEntry[0];
				const claimValue = claimEntry[1];
				const claimId = `claim-${claimName}`;
				let value = Array.isArray(claimValue)
					? JSON.stringify(claimValue)
					: claimValue.toString();

				if (claimName === 'profile' || claimName === 'picture') {
					value = (
						<a href={value} target='_blank'>
							{value}
						</a>
					);
				}
				if (claimName === 'nonce') {
					return;
				}
				if (claimName === 'email') {
					value = (
						<a href={'mailto:' + value + '?subject=Hey!'} target='_blank'>
							{value}
						</a>
					);
				}

				if (claimName === 'treasure' || claimName === 'treasure.hook') {
					return (
						<Table.Row key={claimName} positive>
							<Table.Cell>
								<Label ribbon>{claimName}</Label>
							</Table.Cell>
							<Table.Cell id={claimId}>{value}</Table.Cell>
						</Table.Row>
					);
				}

				return (
					<Table.Row key={claimName}>
						<Table.Cell>{claimName}</Table.Cell>
						<Table.Cell id={claimId}>{value}</Table.Cell>
					</Table.Row>
				);
			})}
		</Table.Body>
	);

	const TabTable = input => (
		<div>
			<Table stackable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Claim</Table.HeaderCell>
						<Table.HeaderCell>
							Value
							<Modal
								onClose={() => setOpen(false)}
								onOpen={() => setOpen(true)}
								open={isOpen}
								trigger={
									<Button floated='right' primary size='tiny'>
										See JWT
									</Button>
								}
								closeIcon
								dimmer
								basic
							>
								<Modal.Content>
									<JSONPretty id='json' data={input} />
								</Modal.Content>
							</Modal>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				{!isLoading && isAuthorized && TableBody(input)}
			</Table>
		</div>
	);

	const TokenTabs = (accessToken, idToken) => {
		let panes = [];

		if (accessToken) {
			panes.push({
				menuItem: {
					key: 'accessToken',
					content: 'Access Token',
				},
				render: () => (
					<Tab.Pane loading={isLoading}>{TabTable(accessToken)}</Tab.Pane>
				),
			});
		}

		if (idToken) {
			panes.push({
				menuItem: {
					key: 'idToken',
					content: 'ID Token',
				},
				render: () => (
					<Tab.Pane loading={isLoading}>{TabTable(idToken)}</Tab.Pane>
				),
			});
		}

		return <Tab panes={panes} />;
	};

	return (
		<div>
			{intro}
			{isUserInfo && TabTable(data)}
			{!isUserInfo && TokenTabs(data?.accessToken, data?.idToken)}
		</div>
	);
};
