/** @format */
import React, { useEffect, useState } from 'react';
import {
	Container,
	Card,
	Divider,
	Grid,
	Header,
	Icon,
	Image,
	Label,
	Segment,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import './styles.css';

export function References() {
	document.body.className = 'sign-in';

	const [cards, setCards] = useState([]);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		const buildCards = async input => {
			let cards = [];

			for (let i = 0; i < input.length; i++) {
				let data = input[i];

				const cardData = {
					title: data?.title,
					description: data?.description,
					comment: data?.comment,
					url: data?.url,
					platforms: data?.platforms,
					type: data?.type,
					isFav: data?.isFav ?? false,
				};

				const card = await buildCard(cardData);

				cards.push(card);
			}

			return cards;
		};

		const buildCard = async data => {
			let extra;

			if (data?.platforms) {
				extra = await buildIcons(data.platforms);
			}

			return (
				<Card raised key={uuid()} link={data.url}>
					<Label attached='bottom right'>{data?.type}</Label>
					{data?.isFav && (
						<Label size='mini' corner='right' icon='heart' color='red' />
					)}
					<Card.Header>{data.title}</Card.Header>
					<Divider />
					<Card.Meta>{data.description}</Card.Meta>
					{data?.platforms && (
						<div>
							<Label className='platform' attached='bottom left'>
								{extra}
							</Label>
						</div>
					)}
				</Card>
			);
		};

		const buildIcons = async platforms => {
			let icons = [];

			platforms.forEach(platform => {
				let color;
				switch (platform) {
					case 'android':
						color = 'green';
						break;
					case 'react':
						color = 'blue';
						break;
					case 'node':
						color = 'green';
						break;
					default:
						color = 'grey';
				}

				icons.push(<Icon name={platform} size='big' color={color} />);
			});

			return icons;
		};

		return fetch('/static/data.json')
			.then(resp => {
				if (resp && resp.ok) {
					return resp.json();
				} else throw resp;
			})
			.then(resp => {
				buildCards(resp.data)
					.then(cards => {
						if (cards && cards.length > 0) {
							setCards(() => cards);
						}
					})
					.then(() => setLoading(() => false))
					.catch(err => {
						console.error(err);
					});
			});
	}, []);

	return (
		<Container text style={{ marginTop: '5em' }} className={'ref-container'}>
			<Header as='h1'>Welcome!</Header>
			Please enjoy my curated page of Okta Developer resources.
			<Divider section />
			<Card.Group stackable centered itemsPerRow={3}>
				{cards}
			</Card.Group>
		</Container>
	);
}
