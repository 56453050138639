/** @format */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Error } from '../Errors';
import {
	SignInForm,
	SignInContext,
	SignUpForm,
	SignInContainer,
	TopContainer,
	BackDrop,
	HeaderContainer,
	HeaderText,
	SmallText,
	InnerContainer,
} from './index';

const BoxContainer = styled.div`
	width: 300px;
	min-height: 550px;
	display: flex;
	flex-direction: column;
	border-radius: 19px;
	background-color: #fff;
	box-shadow: 0 0 2px rgba(32, 44, 57, 0.5);
	position: relative;
	overflow: hidden;
`;

const backdropVariants = {
	expanded: {
		width: '466%',
		height: '2100px',
		borderRadius: '20%',
		transform: 'rotate(60deg)',
		zIndex: 10,
		top: '-1060px',
		left: '-300px',
	},
	collapsed: {
		width: '160%',
		height: '550px',
		borderRadius: '50%',
		transform: 'rotate(60deg)',
	},
};

const expandingTransition = {
	type: 'spring',
	duration: 2.3,
	stiffness: 30,
};

export const SignIn = () => {
	document.body.className = 'sign-in';
	const [isExpanded, setExpanded] = useState(false);
	const [isSignUp, setSignUp] = useState(false);
	const [errorState, setError] = useState({ isError: false });

	const playExpandingAnimation = () => {
		setExpanded(true);
		setTimeout(() => {
			setExpanded(false);
		}, expandingTransition.duration * 1000 - 1500);
	};

	const switchToSignup = () => {
		playExpandingAnimation();
		setTimeout(() => {
			setSignUp(true);
		}, 400);
	};

	const switchToSignin = () => {
		playExpandingAnimation();
		setTimeout(() => {
			setSignUp(false);
		}, 400);
	};

	const contextValue = { switchToSignup, switchToSignin };

	return (
		<SignInContainer id='sign-in'>
			<SignInContext.Provider value={contextValue}>
				<BoxContainer>
					<TopContainer>
						<BackDrop
							initial={false}
							animate={isExpanded ? 'expanded' : 'collapsed'}
							variants={backdropVariants}
							transition={expandingTransition}
						/>
						{!isSignUp && (
							<HeaderContainer>
								<HeaderText>Welcome</HeaderText>
								<HeaderText>Back</HeaderText>
								<SmallText>Please sign in to continue!</SmallText>
							</HeaderContainer>
						)}
						{isSignUp && (
							<HeaderContainer>
								<HeaderText>Create</HeaderText>
								<HeaderText>Account</HeaderText>
								<SmallText>Please sign up to continue!</SmallText>
							</HeaderContainer>
						)}
					</TopContainer>
					<InnerContainer>
						{!isSignUp && <SignInForm />}
						{isSignUp && <SignUpForm />}
					</InnerContainer>
				</BoxContainer>
			</SignInContext.Provider>
		</SignInContainer>
	);
};
