/** @format */

import _ from 'lodash';
import moment from 'moment';

const url = 'https://quotes.rest/qod.json';
const imgUrl = 'https://api.unsplash.com/search/photos';
const defaultQuote = {
	text: "Life is something to do when you can't get to sleep.",
	author: 'Fran Lebowitz',
	tags: ['life', 'sleep'],
	date: '2021-06-29',
	image: 'https://theysaidso.com/img/qod/qod-life.jpg',
};

function imageTag() {
	try {
		const url = `${imgUrl}?client_id=${process.env.REACT_APP_UNSPLASH_KEY}&per_page=50&orientation=squarish&content_filter=high&query=${imageTag}`;

		if (_.isEmpty(image)) {
			return fetch(url)
				.then(response => {
					if (response.ok) {
						return response.json();
					}
				})
				.then(resp => {
					const results = resp?.results;

					const random = getPage(0, results.length);
					const image = results[random].urls?.raw;
					setImage(() => image);
				})
				.catch(err => {
					console.error(`Unable to fetch image [${err}]`);
					setImage(
						'https://images.unsplash.com/photo-1599953941123-8f16f4d399ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
					);
				});
		}

		return image;
	} catch (error) {}
	console.log('🚀 -------------------------------------------------------');
	console.log('🚀 ~ file: Random.jsx ~ line 56 ~ Random ~ error', error);
	console.log('🚀 -------------------------------------------------------');
}

export const getQuote = () => {
	let quote = JSON.parse(localStorage.getItem('qod')) ?? defaultQuote;
	// console.log(quote);

	if (
		!quote ||
		moment(defaultQuote.date).dayOfYear() !== moment().dayOfYear()
	) {
		// console.info('No quote of the day! Fetching one...');

		fetch(url)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
			})
			.then(resp => {
				const quoteRsp = resp.contents.quotes[0];

				quote = {
					text: quoteRsp?.quote,
					author: quoteRsp?.author,
					date: quoteRsp?.date,
					tag: quoteRsp?.tags[0],
					image: quoteRsp?.background,
				};
			})
			.then(() => {
				if (!quote?.image && quote?.tag) {
					quote.image = getImage(resp.tags);
				}
			})
			.catch(err => {
				console.warn(`Unable to fetch quote ${err}`);
				quote = defaultQuote;
			})
			.finally(() => localStorage.setItem('qod', JSON.stringify(quote)));
	}

	return quote;
};
