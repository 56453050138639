/** @format */

import React, { useState, useEffect } from 'react';
import { Container, Message } from 'semantic-ui-react';

export const Error = props => {
	console.log('error props:', props);
	const msg_header = props?.error ?? 'Unknown error';
	const msg = props?.errorDetails ?? `Uh oh! We're not sure what went wrong`;

	return (
		<Message floating error>
			<Message.Header>{msg_header}</Message.Header>
			<p style={{ fontSize: '16px', textAlign: 'left' }}>{msg}</p>
		</Message>
	);
};
