/** @format */

import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

const getDeviceConfig = width => {
	if (width < 560) {
		return 'sm';
	} else if (width >= 560 && width < 992) {
		return 'md';
	} else if (width >= 992) {
		return 'lg';
	}
};

export const useBreakpoint = () => {
	const [brkPnt, setBrkPnt] = useState(() =>
		getDeviceConfig(window.innerWidth)
	);

	useEffect(() => {
		const calcInnerWidth = throttle(function () {
			setBrkPnt(getDeviceConfig(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

	return brkPnt;
};
